<template>
	<div class="container-fluid">
		<br />
		<div class="col-sm-12 text-right">
			<h1>{{ obtener_titulo }}</h1>
		</div>
		<br />
		<div class="d-flex flex-wrap">
			<div v-if="$auth.can('proveedores','tesoreria_proveedor')" class="col-md-4">
				<div class="bpb-card">
					<div class="card-body">
						<div class="text-center">
							<img src="https://static.bienparabien.com/constelacion/img/facturacion.svg" alt="Facturas" width="80px" />
						</div>
						<h4 class="card-title">Facturas</h4>
						<p class="card-subtitle text-muted">Administración de facturas</p>
						<router-link :to="{name: 'proveedores_facturas'}" class="btn complementary-btn">Acceder</router-link>
					</div>
				</div>
			</div>
			<div v-if="$auth.can('proveedores','tesoreria_proveedor')" class="col-md-4">
				<div class="bpb-card">
					<div class="card-body">
						<div class="text-center">
							<img src="https://static.bienparabien.com/constelacion/img/empresas.svg" alt="Proveedores" width="80px" />
						</div>
						<h4 class="card-title">Empresa</h4>
						<p class="card-subtitle text-muted">Información de la empresa</p>
						<router-link :to="{name: 'proveedores_empresa'}" class="btn complementary-btn">Acceder</router-link>
					</div>
				</div>
			</div>

			<div v-if="$auth.can('tesoreria','tesoreria_operador')" class="col-md-4">
				<div class="bpb-card">
					<div class="card-body">
						<div class="text-center">
							<img src="https://static.bienparabien.com/constelacion/img/facturacion.svg" alt="Facturas" width="80px" />
						</div>
						<h4 class="card-title">Facturas</h4>
						<p class="card-subtitle text-muted">Administración de facturas</p>
						<router-link :to="{name: 'tesoreria_facturas'}" class="btn complementary-btn">Acceder</router-link>
					</div>
				</div>
			</div>
			<div v-if="$auth.can('tesoreria','tesoreria_operador')" class="col-md-4">
				<div class="bpb-card">
					<div class="card-body">
						<div class="text-center">
							<img src="https://static.bienparabien.com/constelacion/img/facturacion.svg" alt="Facturas" width="80px" />
						</div>
						<h4 class="card-title">Facturas inválidas</h4>
						<p class="card-subtitle text-muted">Administración de facturas inválidas</p>
						<router-link :to="{name: 'tesoreria_facturas_invalidas'}" class="btn complementary-btn">Acceder</router-link>
					</div>
				</div>
			</div>
			<div v-if="$auth.can('tesoreria','tesoreria_operador') || $auth.can('tesoreria', 'gastos_listar') || $auth.can('tesoreria', 'gastos_misgastos_listar')" class="col-md-4">
				<div class="bpb-card">
					<div class="card-body">
						<div class="text-center">
							<img src="https://static.bienparabien.com/constelacion/img/facturacion.svg" alt="Facturas" width="80px" />
						</div>
						<h4 class="card-title">Gastos</h4>
						<p class="card-subtitle text-muted">Administración de gastos</p>
						<router-link :to="{name: 'tesoreria_gastos'}" class="btn complementary-btn">Acceder</router-link>
					</div>
				</div>
			</div>
			<div v-if="$auth.can('tesoreria','tesoreria_operador')" class="col-md-4">
				<div class="bpb-card">
					<div class="card-body">
						<div class="text-center">
							<img src="https://static.bienparabien.com/constelacion/img/facturacion.svg" alt="Facturas" width="80px" />
						</div>
						<h4 class="card-title">Viaticos</h4>
						<p class="card-subtitle text-muted">Administración de viaticos</p>
						<router-link :to="{name: 'tesoreria_viaticos'}" class="btn complementary-btn">Acceder</router-link>
					</div>
				</div>
			</div>
			<div v-if="$auth.can('tesoreria','tesoreria_operador')" class="col-md-4">
				<div class="bpb-card">
					<div class="card-body">
						<div class="text-center">
							<img src="https://static.bienparabien.com/constelacion/img/proveedores.svg" alt="Proveedores" width="80px" />
						</div>
						<h4 class="card-title">Proveedores</h4>
						<p class="card-subtitle text-muted">Administración de proveedores</p>
						<router-link :to="{name: 'tesoreria_proveedores_admin'}" class="btn complementary-btn">Acceder</router-link>
					</div>
				</div>
			</div>
			<div v-if="$auth.can('tesoreria','tesoreria_operador')" class="col-md-4">
				<div class="bpb-card">
					<div class="card-body">
						<div class="text-center">
							<img src="https://static.bienparabien.com/constelacion/img/empresas.svg" alt="Empresas" width="80px" />
						</div>
						<h4 class="card-title">Empresas</h4>
						<p class="card-subtitle text-muted">Administración de facturas</p>
						<router-link :to="{name: 'tesoreria_empresas'}" class="btn complementary-btn">Acceder</router-link>
					</div>
				</div>
			</div>
			<div v-if="$auth.can('tesoreria','tesoreria_operador')" class="col-md-4">
				<div class="bpb-card">
					<div class="card-body">
						<div class="text-center">
							<img src="https://static.bienparabien.com/constelacion/img/autorizadores.svg" alt="Autorizadores" width="80px" />
						</div>
						<h4 class="card-title">Autorizadores</h4>
						<p class="card-subtitle text-muted">Administración de los proveedores</p>
						<router-link :to="{name: 'tesoreria_autorizadores'}" class="btn complementary-btn">Acceder</router-link>
					</div>
				</div>
			</div>
			<div v-if="$auth.can('tesoreria','tesoreria_preautorizador')" class="col-md-4">
				<div class="bpb-card">
					<div class="card-body">
						<div class="text-center">
							<img src="https://static.bienparabien.com/constelacion/img/autorizadores.svg" alt="Autorizadores" width="80px" />
						</div>
						<h4 class="card-title">Pre-autorizadores</h4>
						<p class="card-subtitle text-muted">Pre autorización de facturas</p>
						<router-link :to="{name: 'tesoreria_preautorizaciones'}" class="btn complementary-btn">Acceder</router-link>
					</div>
				</div>
			</div>
			<div v-if="$auth.can('tesoreria','tesoreria_preautorizador')" class="col-md-4">
				<div class="bpb-card">
					<div class="card-body">
						<div class="text-center">
							<img src="https://static.bienparabien.com/constelacion/img/autorizadores.svg" alt="Autorizadores" width="80px" />
						</div>
						<h4 class="card-title">COI</h4>
						<p class="card-subtitle text-muted">Generación de layout para COI</p>
						<router-link :to="{name: 'tesoreria_coi'}" class="btn complementary-btn">Acceder</router-link>
					</div>
				</div>
			</div>
			<div v-if="this.$auth.can('tesoreria', 'tesoreria_directivos')" class="col-md-4">
				<div class="bpb-card">
					<div class="card-body">
						<div class="text-center">
							<img src="https://static.bienparabien.com/constelacion/img/autorizaciones.svg" alt="Autorizaciones" width="80px" />
						</div>
						<h4 class="card-title">Autorizaciones</h4>
						<p class="card-subtitle text-muted">Autorización de facturas</p>
						<router-link :to="{name: 'tesoreria_autorizaciones'}" class="btn complementary-btn">Acceder</router-link>
					</div>
				</div>
			</div>
			<div v-if="this.$auth.can('tesoreria', 'tesoreria_directivos')" class="col-md-4">
				<div class="bpb-card">
					<div class="card-body">
						<div class="text-center">
							<img src="https://static.bienparabien.com/constelacion/img/autorizaciones.svg" alt="Autorizaciones" width="80px" />
						</div>
						<h4 class="card-title">Autorizaciones Gastos</h4>
						<p class="card-subtitle text-muted">Autorización de gastos</p>
						<router-link :to="{name: 'tesoreria_autorizaciones_gastos'}" class="btn complementary-btn">Acceder</router-link>
					</div>
				</div>
			</div>
			<div v-if="this.$auth.can('tesoreria', 'tesoreria_directivos')" class="col-md-4">
				<div class="bpb-card">
					<div class="card-body">
						<div class="text-center">
							<img src="https://static.bienparabien.com/constelacion/img/autorizaciones.svg" alt="Autorizaciones" width="80px" />
						</div>
						<h4 class="card-title">Autorizaciones Viaticos</h4>
						<p class="card-subtitle text-muted">Autorización de viaticos</p>
						<router-link :to="{name: 'tesoreria_autorizaciones_viaticos'}" class="btn complementary-btn">Acceder</router-link>
					</div>
				</div>
			</div>
			<div v-if="this.$auth.can('tesoreria', 'tesoreria_directivos')" class="col-md-4">
				<div class="bpb-card">
					<div class="card-body">
						<div class="text-center">
							<img src="https://static.bienparabien.com/constelacion/img/autorizaciones.svg" alt="Autorizaciones" width="80px" />
						</div>
						<h4 class="card-title">Autorizaciones para Directivos</h4>
						<p class="card-subtitle text-muted">Autorización de facturas</p>
						<router-link :to="{name: 'tesoreria_autorizaciones_directivos'}" class="btn complementary-btn">Acceder</router-link>
					</div>
				</div>
			</div>
			<div v-if="this.$auth.can('tesoreria', 'tesoreria_autorizador_finanzas')" class="col-md-4">
				<div class="bpb-card">
					<div class="card-body">
						<div class="text-center">
							<img src="https://static.bienparabien.com/constelacion/img/autorizaciones.svg" alt="Autorizaciones" width="80px" />
						</div>
						<h4 class="card-title">Autorizaciones de gastos para Directivos</h4>
						<p class="card-subtitle text-muted">Autorización de gastos</p>
						<router-link :to="{name: 'tesoreria_directivos_gastos'}" class="btn complementary-btn">Acceder</router-link>
					</div>
				</div>
			</div>
			<div v-if="$auth.can('tesoreria', 'configuracion_listar_menu')" class="col-md-4">
				<div class="bpb-card">
					<div class="card-body">
						<div class="text-center">
							<img src="https://static.bienparabien.com/constelacion/img/gear.svg" alt="Autorizaciones" width="80px" />
						</div>
						<h4 class="card-title">Configuración</h4>
						<p class="card-subtitle text-muted">Configuración de diversas directivas de la aplicación</p>
						<router-link :to="{name: 'tesoreria_configuracion'}" class="btn complementary-btn">Acceder</router-link>
					</div>
				</div>
			</div>
			<div v-if="$auth.can('tesoreria', 'catalogos_crear')" class="col-md-4">
				<div class="bpb-card">
					<div class="card-body">
						<div class="text-center">
							<img src="https://static.bienparabien.com/constelacion/img/gear.svg" alt="Autorizaciones" width="80px" />
						</div>
						<h4 class="card-title">Catálogos</h4>
						<p class="card-subtitle text-muted">Catálogos</p>
						<router-link :to="{name: 'tesoreria_catalogos'}" class="btn complementary-btn">Acceder</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		mounted: function() {
			if (this.$auth.can('proveedores','tesoreria_proveedor')) {
				let menu = {
					title: 'Tesorería'
					,proveedores_facturas: 'Facturas'
					,proveedores_empresa: 'Empresa'
				};

				this.$store.commit('setMenu',[menu]);
			}else {
				this.$log.info('dashboard tesoreria montado');

				let menu = {title: 'Tesorería'};

				if (this.$auth.can('tesoreria', 'tesoreria_operador'))
					menu['tesoreria_facturas'] = 'Facturas';

				if (this.$auth.can('tesoreria', 'tesoreria_operador'))
					menu['tesoreria_facturas_invalidas'] = 'Facturas inválidas';

				if (this.$auth.can('tesoreria', 'tesoreria_operador') || this.$auth.can('tesoreria', 'gastos_listar') || this.$auth.can('tesoreria', 'gastos_misgastos_listar'))
					menu['tesoreria_gastos'] = 'Gastos';

				if (this.$auth.can('tesoreria', 'tesoreria_operador'))
					menu['tesoreria_viaticos'] = 'Viaticos';

				if (this.$auth.can('tesoreria', 'tesoreria_operador'))
					menu['tesoreria_proveedores_admin'] = 'Proveedores';

				if (this.$auth.can('tesoreria', 'tesoreria_operador'))
					menu['tesoreria_empresas'] = 'Empresas';

				if (this.$auth.can('tesoreria', 'tesoreria_operador'))
					menu['tesoreria_autorizadores'] = 'Autorizadores';

				if (this.$auth.can('tesoreria','tesoreria_preautorizador'))
					menu['tesoreria_preautorizaciones'] = 'Pre-autorizaciones';

				if (this.$auth.can('tesoreria','tesoreria_operador'))
					menu['tesoreria_coi'] = 'COI';

				if (this.$auth.can('tesoreria', 'tesoreria_autorizador'))
					menu['tesoreria_autorizaciones'] = 'Autorizaciones';

				if (this.$auth.can('tesoreria', 'tesoreria_directivos'))
					menu['tesoreria_autorizaciones_gastos'] = 'Autorizaciones Gastos';

				if (this.$auth.can('tesoreria', 'tesoreria_directivos'))
					menu['tesoreria_autorizaciones_viaticos'] = 'Autorizaciones Viaticos';

				if (this.$auth.can('tesoreria', 'tesoreria_directivos'))
					menu['tesoreria_autorizaciones_directivos'] = 'Autorizaciones para Directivos';

				if (this.$auth.can('tesoreria', 'tesoreria_autorizador_finanzas'))
					menu['tesoreria_directivos_gastos'] = 'Autorizaciones de gastos para Directivos';

				if (this.$auth.can('tesoreria', '*'))
					menu['tesoreria_configuracion'] = 'Configuración';

				if (this.$auth.can('tesoreria', 'tesoreria_operador'))
					menu['tesoreria_catalogos'] = 'Catálogos';

				this.$store.commit('setMenu',[menu]);
			}
		}
		,computed: {
			obtener_titulo: function() {
				return !this.$auth.can('proveedores','tesoreria_proveedor') ? 'Tesorería' : 'Proveedores';
			}
		}
	}
</script>